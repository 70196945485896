import $ from "jquery";
import { logFactory } from "GlobalSite";

const compName = "comp_tech_generic-tabs";
const Log = logFactory(compName);
const { log } = Log;

log("--->");

$(function domReady() {
  // accessible tabs from : https://www.w3.org/WAI/ARIA/apg/patterns/tabs/examples/tabs-manual/
  const $tabsContainer = $(".tabs-component__tabs");
  const $tabs = $tabsContainer.find(".tabs-component__tab");
  const $panels = $(".tabs-component__panel");

  const $firstTab = $tabs.first();
  const $lastTab = $tabs.last();

  function setSelectedTab($currentTab) {
    const currentPanelId = $($currentTab).attr("aria-controls");

    $tabs
      .not($currentTab)
      .attr("aria-selected", "false")
      .attr("tabIndex", "-1");
    $panels.not(`#${currentPanelId}`).addClass("is-hidden");

    $currentTab.attr("aria-selected", "true").removeAttr("tabindex");
    $(`#${currentPanelId}`).removeClass("is-hidden");
  }

  function moveFocusToTab($currentTab) {
    $currentTab.trigger("focus");
  }

  function moveFocusToPreviousTab($currentTab) {
    if ($currentTab.is($firstTab)) {
      moveFocusToTab($lastTab);
    } else {
      const index = $tabs.index($currentTab);
      moveFocusToTab($($tabs.get(index - 1)));
    }
  }

  function moveFocusToNextTab($currentTab) {
    if ($currentTab.is($lastTab)) {
      moveFocusToTab($firstTab);
    } else {
      const index = $tabs.index($currentTab);
      moveFocusToTab($($tabs.get(index + 1)));
    }
  }

  $tabsContainer.on("click", ".tabs-component__tab", function () {
    setSelectedTab($(this));
  });

  //keyboard Event
  const KEY_ACTIONS = {
    ArrowLeft: ($tab) => moveFocusToPreviousTab($tab),
    ArrowRight: ($tab) => moveFocusToNextTab($tab),
    Home: () => moveFocusToTab($firstTab),
    End: () => moveFocusToTab($lastTab),
  };

  $tabsContainer.on("keydown", ".tabs-component__tab", (event) => {
    const action = KEY_ACTIONS[event.key];

    if (action) {
      event.preventDefault();
      event.stopPropagation();
      action($(event.currentTarget));
    }
  });
});
